import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25')
];

export const server_loads = [0,2,3,5];

export const dictionary = {
		"/": [~6],
		"/404": [~7],
		"/auth/invite": [9],
		"/auth/oauth-error": [~10],
		"/bundles": [~11],
		"/bundles/[bundle]": [12,[2]],
		"/courses": [~13],
		"/courses/[course]": [~14,[3]],
		"/courses/[course]/lessons": [15,[3]],
		"/courses/[course]/review": [16,[3]],
		"/pay": [17],
		"/privacy": [~18],
		"/purchase-success": [~19],
		"/puzzles": [~20,[4]],
		"/puzzles/[puzzle]": [~21,[4]],
		"/terms": [~22],
		"/worksheets": [~23],
		"/worksheets/[worksheet]": [24,[5]],
		"/worksheets/[worksheet]/review": [~25,[5]],
		"/[content_page]": [~8]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';